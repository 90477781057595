import React from 'react';
import {Redirect, useParams} from "react-router-dom";
import {useAuth} from "../../auth";

export default function QRCodeRedirector() {
    const {version, id} = useParams();
    const auth = useAuth();

    if (version === "1") {
        const workshopId = auth.data.workshopId;
        const currentModuleId = auth.data.currentModuleId;
        if (!workshopId || !currentModuleId) {
            return <h1>Cannot determine where to redirect. Are you logged in as an admin user? QR Codes are not supported for admin users</h1>
        }

        return <Redirect to={`/workshops/${workshopId}/modules/${currentModuleId}/${id}?m=1`}/>
    }


    return <Redirect to="/"/>;
}
        
