import React, {useState} from 'react';
import {useAuth} from "../../auth"
import {Link, useHistory, useLocation} from "react-router-dom";

import "./Login.css";
import LoginHeader from "./LoginHeader";
import Lottie from 'react-lottie';
import loadingAnimation from '../../animations/loading-blue.json';


export default function Login() {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState();

    const lottieOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };


    let history = useHistory();
    let location = useLocation();
    let {from} = location.state || {from: {pathname: "/"}};

    if (localStorage.getItem("loggedOut")) {
        from = {pathname: "/"};
    }

    const auth = useAuth();

    function onSubmit(evt) {
        evt.preventDefault();
        setError("");

        if (!username) {
            setError("Please provide a valid e-mail address")
            return;
        }

        if (!password) {
            setError("Please provide a valid password")
            return;
        }

        setLoading(true);
        auth.signin(username, password)

            .then(() => {
                localStorage.removeItem("loggedOut");
                history.replace(from);
            })
            .catch((error) => {
                setError(`Error: ${error}`)
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <div className="Login">
            <LoginHeader/>
            <form onSubmit={onSubmit}>
                <h1>Log In</h1>
                <input type="text" name="username" placeholder="Enter an email" onChange={e => setUserName(e.target.value)}/>
                <p className="error">{error}</p>

                <input type="password" autoComplete="current-password" onChange={e => setPassword(e.target.value)}/>
                <Link to="/forgot-password">Forgot your password?</Link>

                <div>
                    <button type="submit" className={loading ? "Loading" : ""} disabled={loading}>
                        <span className="Login-Button-Text">Log In</span>
                        <span className="Login-Button-Loading-Animation"><Lottie options={lottieOptions} width={90}/></span>
                    </button>
                </div>
            </form>
        </div>
    )
}