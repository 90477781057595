import React from 'react';
import './BreadCrumbs.css';
import {Link} from "react-router-dom";

export default function BreadCrumbs(props) {
    return (
        <div className="BreadCrumbs">
            <ul>
                {
                    props.breadcrumbs.map(value => (
                        <li key={value.url}>
                            <Link to={value.url}>{value.name}</Link>
                        </li>
                    ))
                }
            </ul>
        </div>
    );
}