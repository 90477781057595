import React, {useEffect, useState} from 'react';
import './WorkshopOverview.css';
import Container from "../Container/Container";
import {mdiCardsDiamond, mdiPlus, mdiViewCarouselOutline} from "@mdi/js";
import {useAuth} from "../../auth";
import {Link, useParams} from "react-router-dom";
import {post} from "../../api/api";
import Icon from "@mdi/react";

export default function WorkshopOverview() {
    const {workshopId} = useParams();
    const [error, setError] = useState();
    const [workshop, setWorkshop] = useState();
    const [modules, setModules] = useState([]);
    const auth = useAuth();

    let currentModule = modules.filter((module) => module.isCurrentModule !== 0);
    if(currentModule) {
        currentModule = currentModule[0];
    } else {
        currentModule = null;
    }
    
    let sidebar = [
        {
            text: "Overview",
            icon: mdiViewCarouselOutline
        }
    ];

    useEffect(() => {
        (async function () {
            setWorkshop(await post('/workshops/get', {"workshopId": workshopId}));
            setModules(await post('/workshops/list-modules', {"workshopId": workshopId}));
        })();
    }, [auth.data.token, workshopId])


    return (
        <Container sidebar={sidebar} error={error}>
            <div className="WorkshopOverview">
                <h1>{workshop?.name}</h1>
                <hr/>

                <div className="Horizontal">
                    <div className="ModuleList">
                        <ul>
                            {modules.map(module => (
                                <li key={module.id} className={module.isCurrentModule !== 0 ? "IsCurrentModule" : ""}>
                                    <Link to={`/workshops/${workshopId}/modules/${module.id}`}>{module.name}</Link>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="CurrentModuleDescription">
                        <div className="Section">
                            <div className="Section-Header">
                                <h2>{currentModule?.name}</h2>
                                <p>{currentModule?.description?.subtitle}</p>
                            </div>
                            <div className="Section-Content">
                                <ul className="DiamondList">
                                    {currentModule?.description?.goals?.map(goal => (<li><Icon path={mdiCardsDiamond}/>{goal}</li>))}
                                </ul>
                                
                                <div>
                                    {currentModule?.description?.content}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </Container>
    );
}