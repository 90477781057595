import {useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import LoginHeader from "../Login/LoginHeader";

export default function SetPassword() {

    const [password, setPassword] = useState();
    const [passwordRepeat, setPasswordRepeat] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState();


    const location = useLocation();
    const history = useHistory();

    let searchParams = new URLSearchParams(location.search)
    let token = searchParams.get("token");
    if (!token) {
        history.replace("/");
    }

    function onSubmit(evt) {
        evt.preventDefault();
        setError("");

        if (password !== passwordRepeat) {
            setError("Passwords do not match")
            return;
        }

        if (!password) {
            setError("Please provide a valid password")
            return;
        }

        setLoading(true);
        return fetch(process.env.REACT_APP_API_URL + '/user/set-password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token: token,
                password: password,
            })
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    setError(`Error: ${data.error}`);
                    return;
                }

                history.replace("/login");
            })
            .catch((error) => {
                setError("Error: " + error);
            })
            .finally(() => {
                setLoading(false);
            });
    }


    return (
        <div className="Login">
            <LoginHeader/>

            <form onSubmit={onSubmit}>
                <h1>Set Password</h1>
                <input type="password" placeholder="New password" autoComplete="new-password" onChange={e => setPassword(e.target.value)}/>

                <input type="password" placeholder="New password (repeat)" autoComplete="new-password" onChange={e => setPasswordRepeat(e.target.value)}/>
                <p className="error">{error}</p>

                <div>
                    <button type="submit" className={loading ? "Loading" : ""} disabled={loading}>Set Password</button>
                </div>
            </form>
        </div>
    )
}