import React from 'react';
import {useAuth} from "../../auth";
import {useHistory} from "react-router-dom";


export default function Demo() {
    const auth = useAuth();
    const history = useHistory();

    auth.demoSignin(() => {
            history.replace("/");
        }, (error) => {
            alert(error);
        }
    );

    return null;
}