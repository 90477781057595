let token = null;
let onError = () => ({});
let onLoading = () => ({});

export function setToken(apiToken) {
    token = apiToken;
}

export function setOnError(onErrorFunc) {
    onError = onErrorFunc;
}

export function setOnLoading(onLoadingFunc) {
    onLoading = onLoadingFunc;
}

export async function post(relUrl, body) {
    onLoading(true);
    
    try {
        const response = await fetch(process.env.REACT_APP_API_URL + relUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(body)
        });

        let data = await response.json();

        if (data.error) {
            onError(`Error: ${data.error}`, data.redirectToLogin);
            return null;
        }

        return data;
    } catch (e) {
        onError(`Error: ${e}`);
        return null;
    } finally {
        onLoading(false);
    }
}
