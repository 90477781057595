import create from 'zustand';
import produce from 'immer';
import {post} from "./api/api";

export const useStore = create((set, get) => ({
    auth: JSON.parse(localStorage.getItem("auth")),
    setAuth: (auth) => set(produce(state => {
        state.auth = auth;
    })),

    isAuthenticated: () => {
        return get().auth?.token;
    },

    hasEditRights: () => {
        const role = get().auth?.role;
        if (role === 'admin' || role === 'leader') {
            return true;
        }
        return false;
    },

    error: null,
    setError: (err, redirect) => set(state => {
        if (redirect) {
            window.location.href = "/login"
        }

        return {error: err}
    }),


    loading: false,
    setLoading: (loading) => set(state => {
        state.loading = loading;
    }),


    contextMenu: null,
    setContextMenu: (contextMenu) => {
        set(state => {
            state.contextMenu = contextMenu;
        })
    },

    module: null,
    fetchModule: async (moduleId) => {
        const data = await post('/modules/get', {
            "moduleId": moduleId
        });

        set(produce(state => {
            state.module = data;
        }));

        return data;
    },

    path: null,
    directoryListing: [],
    fetchDirectoryListing: async (moduleId, path) => {
        const files = await post('/files/list', {"moduleId": moduleId, "path": path});

        set(produce(state => {
            state.path = path;
            state.directoryListing = files;
            state.galleryFiles = files.filter(o => o.thumbnail);
        }));

        return files;
    },

    refreshDirectoryListing: async () => {
        const state = get();
        state.fetchDirectoryListing(state.module.id, state.path);
    },

    galleryFiles: [],
    galleryIndex: -1,

    navigateGallery: (diff) => {
        set(produce(state => {
            state.galleryIndex = get().galleryIndex + diff;

            const lastIndex = get().galleryFiles.length - 1;
            if (state.galleryIndex < 0) {
                state.galleryIndex = lastIndex;
            } else if (state.galleryIndex > lastIndex) {
                state.galleryIndex = 0;
            }
        }));
    },

    setGalleryItem: (galleryItem) => {
        set(produce(state => {
            state.galleryIndex = get().galleryFiles.indexOf(galleryItem);
            state.galleryItem = galleryItem;
        }));
    },

    workshopEditorModules: [],
    fetchWorkshopEditorModules: async (workshopId) => {
        const modules = await post("/workshops/list-modules", {workshopId: workshopId});

        if (modules) {
            set(produce(state => {
                state.workshopEditorModules = modules;
            }));
        }

        return modules;
    },

})); 