import React, {useEffect, useRef, useState} from 'react';
import './AdminWorkshopEditor.css';
import Container from "../Container/Container";
import {mdiAccount, mdiAccountMultiple, mdiAccountPlus, mdiClose, mdiDotsVertical, mdiFolderMultipleOutline, mdiLibrary, mdiMenuDown, mdiPlus} from "@mdi/js";
import {Link, useParams} from "react-router-dom";
import {useStore} from "../../store";
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";
import {post} from "../../api/api";
import Icon from "@mdi/react";
import Modal from "../Modal/Modal";
import {isBlank, isEmail} from "../../util/string";
import {FaSearchengin} from "react-icons/all";


function Workshop({module, workshopId}) {
    const key = module.id;
    const [deleted, setDeleted] = useState(false);
    const fetchWorkshopEditorModules = useStore(state => state.fetchWorkshopEditorModules);
    const contextMenu = useStore(state => state.contextMenu);
    const setContextMenu = useStore(state => state.setContextMenu);

    function markAsActive(evt) {
        evt.preventDefault();
        (async function () {
            await post('/modules/set-as-current', {"moduleId": module.id});
            fetchWorkshopEditorModules(module.workshopId);
            setContextMenu(null);
        })();
    }

    function renameModule(evt) {
        evt.preventDefault();

        const name = prompt("Please enter workshop name");
        if (name) {
            (async function () {
                await post('/modules/rename', {id: module.id, name: name});
                fetchWorkshopEditorModules(module.workshopId);
                setContextMenu(null);
            })();
        }
    }

    function deleteModule(evt) {
        evt.preventDefault();
        if (module.isCurrentModule) {
            window.alert("Cannot remove active module. Mark other module as active and try again.")
            return;
        }
        if (window.confirm("Are you sure you want to delete this module?")) {
            (async function () {
                await post('/modules/delete', {"moduleId": module.id});
                setContextMenu(null);
                setDeleted(true);
            })();
        }
    }

    if (deleted) {
        return <></>;
    }

    return <li class="Module">
        <Link to={`/admin/overview/${(workshopId)}/modules/${module.id}`} className={`SmallButton ${module.isCurrentModule ? "Current-Module" : ""}`}>
            <Icon path={mdiFolderMultipleOutline}/>
            <span>{module.name}</span>
        </Link>
        <a href="{window.location.href}" onClick={(evt) => {
            evt.preventDefault();
            setContextMenu(contextMenu === key ? null : key);
        }} className="ContextMenuButton ContextMenuButton-Center">
            <Icon path={mdiDotsVertical}/>
        </a>

        <div className={`Menu ContextMenu ContextMenu-Center ${contextMenu !== key ? "Hidden" : ""}`}>
            <ul>
                {!module.isCurrentModule ?
                    <li>
                        <a href={window.location.href} onClick={markAsActive}>Mark as active</a>
                    </li>
                    : ""}
                <li>
                    <a href={window.location.href} onClick={renameModule}>Rename</a>
                </li>
                <li>
                    <a href={window.location.href} onClick={deleteModule}>Delete</a>
                </li>
            </ul>
        </div>
    </li>;
}

export default function AdminWorkshopEditor(props) {
    const {workshopId} = useParams();
    const [workshop, setWorkshop] = useState([]);
    const [participants, setParticipants] = useState([]);
    const [addModuleModalVisible, setAddModuleModalVisible] = useState(false);
    const [moduleTemplates, setModuleTemplates] = useState([]);
    const [addUserModalVisible, setAddUserModalVisible] = useState(false);
    const [addUserModalRole, setAddUserModalRole] = useState();
    const [addUserModalError, setAddUserModalError] = useState("");
    const addUserModalRef = useRef(null);
    const selectModuleRef = useRef(null);
    const [breadcrumbs, setBreadCrumbs] = useState([]);
    const workshopEditorModules = useStore(state => state.workshopEditorModules);
    const fetchWorkshopEditorModules = useStore(state => state.fetchWorkshopEditorModules);


    let sidebar = [
        {
            text: "Clients",
            icon: mdiAccountMultiple,
            link: "/admin/overview",
        }, {
            text: "Library",
            icon: mdiLibrary,
            link: "/admin/library",
        },
    ];

    useEffect(() => {
        (async function () {
            const workshop = await post("/workshops/get", {workshopId: workshopId});
            setWorkshop(workshop)

            setParticipants(await post("/workshops/participants", {workshopId: workshopId}))

            fetchWorkshopEditorModules(workshopId);

            setBreadCrumbs([
                {name: "Client Overview", url: `/admin/overview`},
                {name: workshop.name, url: `/admin/overview/${workshopId}`}
            ]);
        })();
    }, [workshopId]);

    function AddModuleButton(evt) {
        evt.preventDefault();

        (async function () {
            const moduleTemplates = await post('/admin/list-module-templates') ?? [];
            setModuleTemplates(moduleTemplates);
        })();

        setAddModuleModalVisible(true);
    }

    function addModule(evt) {
        evt.preventDefault();

        (async function () {
            await post('/workshops/add-module', {
                "workshopId": workshopId,
                "moduleTemplateId": selectModuleRef.current.value,
            });

            // Refresh
            fetchWorkshopEditorModules(workshopId);
            setAddModuleModalVisible(false);
        })();
    }

    function deleteUser(evt, user) {
        evt.preventDefault();
        if (window.confirm(`Are you sure you want to delete the user ${user.email}?`)) {
            (async function () {
                await post('/admin/delete-user', {
                    "userId": user.id
                });

                // Refresh
                setParticipants(await post("/workshops/participants", {workshopId: workshopId}))
                setAddModuleModalVisible(false);
            })();
        }
    }

    function toggleAddUserModal(evt, role) {
        evt.preventDefault();
        setAddUserModalRole(role);
        addUserModalRef.current.value = "";
        setAddUserModalError(null);
        setAddUserModalVisible(true);
    }

    function addUsers(evt) {
        evt.preventDefault();

        const emails = addUserModalRef.current.value.split(/\r?\n/).filter(s => !isBlank(s));
        const errorEmails = emails.filter(s => !isEmail(s));

        if (errorEmails.length > 0) {
            setAddUserModalError("Invalid e-mail address(es): " + errorEmails.join(", "))
            return;
        }

        const users = emails.map(email => ({email: email, role: addUserModalRole, workshopId: workshopId}));
        (async function () {
            await post('/admin/add-users', users);

            // Refresh
            setParticipants(await post("/workshops/participants", {workshopId: workshopId}))
            setAddUserModalVisible(false);
        })();
    }

    function sendWelcomeEmails(evt) {
        evt.preventDefault();

        (async function () {
            if (window.confirm("E-mails will be send to people who have not received a welcome email before")) {
                let result = await post('/admin/send-welcome-mails', {workshopId: workshopId});
                alert(result.numberOfEmailsSent + " e-mails sent");
            }
        })();
    }

    return (
        <Container sidebar={sidebar}>
            <div className="AdminWorkshopEditor">
                <BreadCrumbs breadcrumbs={breadcrumbs}/>
                <div className="Desktop-Only">
                    {/*<Link to="#"  className="Top-Button"><Icon path={mdiAccount}/></Link>*/}
                </div>

                <h1>{workshop.name}</h1>
                <hr/>

                <ul className="Modules">
                    {workshopEditorModules.map(module => (
                        <Workshop key={module.id} workshopId={workshopId} module={module}/>
                    ))}

                    <li>
                        <a href={document.location.path} onClick={AddModuleButton} class="Add-Button">
                            <Icon path={mdiPlus}/>
                            <span>Add module</span>
                        </a>
                    </li>
                </ul>

                <div className="Users">
                    <h1>The team</h1>
                    <h2>Team leader(s) <a href={document.location.path} onClick={((evt) => (toggleAddUserModal(evt, "leader")))} className="AddUser"><Icon path={mdiAccountPlus}/></a></h2>
                    <ul>
                        {participants.filter(user => user.role === "leader").map(user => (
                            <li key={user.id}>
                                <Icon path={mdiAccount} className="UserIcon"/>
                                <span className="UserName">{user.email}</span>
                                <a href={document.location.href} onClick={(evt => (deleteUser(evt, user)))} className="DeleteButton"><Icon path={mdiClose}/></a>
                            </li>
                        ))}
                    </ul>

                    <h2>Team <a href={document.location.path} onClick={((evt) => (toggleAddUserModal(evt, "participant")))} className="AddUser"><Icon path={mdiAccountPlus}/></a></h2>
                    <ul>
                        {participants.filter(user => user.role === "participant").map(user => (
                            <li key={user.id}>
                                <Icon path={mdiAccount} className="UserIcon"/>
                                <span className="UserName">{user.email}</span>
                                <a href={document.location.href} onClick={(evt => (deleteUser(evt, user)))} className="DeleteButton"><Icon path={mdiClose}/></a>
                            </li>
                        ))}
                    </ul>

                    <div className="SendEmailsWrapper">
                        <a href={document.location.path} className="SendEmailsButton" onClick={sendWelcomeEmails}>Send welcome e-mails</a>
                    </div>
                </div>
            </div>

            <Modal visible={addModuleModalVisible} requestClose={() => setAddModuleModalVisible(false)}>
                <h1>Select a module</h1>
                <form onSubmit={addModule}>
                    <div className="SelectWrapper">
                        <select ref={selectModuleRef}>
                            {moduleTemplates.map(moduleTemplate => (
                                <option value={moduleTemplate.id}>{moduleTemplate.name}</option>
                            ))}
                        </select>
                        <Icon path={mdiMenuDown}/>
                    </div>
                </form>

                <a href={window.location.href} onClick={addModule} className="PopupButtonConfirm">Confirm</a>
            </Modal>

            <Modal visible={addUserModalVisible} requestClose={() => setAddUserModalVisible(false)}>
                <h1>Add {addUserModalRole}(s)</h1>
                <p>Add the {addUserModalRole}s' emails to create a team. By adding people to the team, the system will create an account for them.</p>
                <form>
                    <textarea ref={addUserModalRef} placeholder={"person1@example.com\nperson2@example.com\nperson3@example.com"}/>
                </form>
                <div className="Modal-Form-Error">{addUserModalError}</div>

                <a href={window.location.href} onClick={addUsers} className="PopupButtonConfirm">Confirm</a>
            </Modal>
        </Container>
    );
}