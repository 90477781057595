import React, {useState} from 'react';
import './QRCodeScanner.css';
import Container from "../Container/Container";
import QrReader from 'react-qr-reader'
import {useHistory} from "react-router-dom";

export default function QRCodeScanner() {
    const history = useHistory();
    const [error, setError] = useState();


    function handleScan(data) {
        if (data) {
            let origin = document.location.origin;
            
            if (data.startsWith(origin)) {
                history.replace(data.substring(origin.length));
                return;
            }
        }
    }

    function handleError(err) {
        setError(err);
    }


    return (
        <Container error={error}>
            <div className="QRCodeScanner">
                <QrReader
                    delay={300}
                    onError={handleError}
                    onScan={handleScan}
                    showViewFinder={false}
                    className="Reader"
                />

                <div className="Instructions">
                    Scan your QR code to upload your work!
                </div>
            </div>
        </Container>
    );
}