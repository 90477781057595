import React, {useState} from 'react';
import './FileElement.css';
import {mdiDotsVertical, mdiFileOutline, mdiFolderOutline} from "@mdi/js";
import {useAuth} from "../../auth";
import {Link, useParams} from "react-router-dom";
import Icon from "@mdi/react";
import {base64toBlob} from "../../util/base64";
import {useStore} from "../../store";
import {post} from "../../api/api";

export default function FileElement({fileInfo, baseUrl}) {
    const {moduleId} = useParams();
    const [deleted, setDeleted] = useState(false);
    const auth = useAuth();
    const [module, refreshDirectoryListing, setGalleryItem] = useStore(state => [state.module, state.refreshDirectoryListing, state.setGalleryItem]);
    const contextMenu = useStore(state => state.contextMenu);
    const setContextMenu = useStore(state => state.setContextMenu);

    const key = fileInfo.path;

    let icon = mdiFileOutline;
    let link = "#";
    let onClick = (evt) => ({});

    let thumbnail;
    if (fileInfo.isDirectory === true) {
        icon = mdiFolderOutline;
        link = `${baseUrl(module)}/${fileInfo.path}/`;
    } else {
        icon = mdiFileOutline;

        if (fileInfo.thumbnail) {
            thumbnail = URL.createObjectURL(base64toBlob(fileInfo.thumbnail));
            
            onClick = (evt) => {
                evt.preventDefault();
                setGalleryItem(fileInfo)
            }
        } else {
            link = process.env.REACT_APP_API_URL + fileInfo.relativeUrl;
        }
    }

    function renameFile(evt) {
        evt.preventDefault();

        const name = prompt("Please enter new name");
        if (name) {
            (async function () {
                await post('/files/rename', {
                    moduleId: module.id,
                    path: fileInfo.path,
                    newName: name,
                });

                refreshDirectoryListing();
                setContextMenu(null);
            })();
        }
    }

    function deleteFile(evt) {
        evt.preventDefault();
        if (window.confirm("Are you sure you want to delete this file?")) {
            (async function () {
                await post('/files/delete', {
                    "moduleId": moduleId,
                    "path": fileInfo.path
                });

                setDeleted(true);
            })();
        }
    }

    if (deleted) {
        return <></>;
    }

    return <>
        <li className="File">
            <Link to={link} onClick={onClick} target={!fileInfo.isDirectory ? "_blank" : ""}>
                <div className="Thumbnail">
                    {fileInfo.thumbnail ? <img src={URL.createObjectURL(base64toBlob(fileInfo.thumbnail))} alt={fileInfo.name}/> : ""}
                    {fileInfo.isDirectory === true  ? <Icon path={icon} className="DirectoryIcon"/> : ""}
                </div>
                <div className="Filename">
                    <Icon path={icon}/>
                    {fileInfo.name}
                </div>
            </Link>
            {/*TODO create list dynamically later based on roles*/}
            {auth.data.role === "admin" || auth.data.role === "leader" ?
                <a href="{window.location.href}" onClick={(evt) => {
                    evt.preventDefault();
                    setContextMenu(contextMenu === key ? null : key);
                }} className="ContextMenuButton ContextMenuButton-Top">
                    <Icon path={mdiDotsVertical}/>
                </a>
                : ""
            }
            
            <div className={`Menu ContextMenu ContextMenu-Top ${contextMenu !== key ? "Hidden" : ""}`}>
                <ul>
                    <li>
                        <a href={window.location.href} onClick={renameFile}>Rename</a>
                    </li>
                    <li>
                        <a href={window.location.href} onClick={deleteFile}>Delete</a>
                    </li>
                    {/*{["admin", "leader"].includes(auth.data.role) ?*/}
                    {/*    <li>*/}
                    {/*        <Link onClick={comment}>Comment</Link>*/}
                    {/*    </li>*/}
                    {/*    : ""}*/}
                </ul>
            </div>
        </li>
    </>
}