import React, {useCallback, useEffect, useRef, useState} from 'react';
import './AdminWorkshopOverview.css';
import Container from "../Container/Container";
import {mdiAccountMultiple, mdiDotsVertical, mdiFolderMultipleOutline, mdiLibrary, mdiMenuDown, mdiPlus} from "@mdi/js";
import Icon from "@mdi/react";
import {useAuth} from "../../auth";
import {Link} from "react-router-dom";
import {useStore} from "../../store";
import Modal from "../Modal/Modal";
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";
import {post} from "../../api/api";

function WorkshopButton({updateWorkshops, workshop}) {
    const [moveModalVisible, setMoveModalVisible] = useState(false);
    const contextMenu = useStore(state => state.contextMenu);
    const setContextMenu = useStore(state => state.setContextMenu);
    const selectStateRef = useRef(null);

    const key = workshop.id;

    function showStateModal(evt) {
        evt.preventDefault();
        setMoveModalVisible(true);
        setContextMenu(null);
    }

    function selectState(evt) {
        evt.preventDefault();

        (async function () {
            await post('/workshops/set-state', {
                "workshopId": workshop.id,
                "state": selectStateRef.current.value,
            });

            // Refresh
            updateWorkshops();
            setMoveModalVisible(false);
        })();
    }

    function renameWorkshop(evt) {
        evt.preventDefault();

        const name = prompt("Please enter workshop name");
        if (name) {
            (async function () {
                await post('/workshops/rename', {id: workshop.id, name: name});
                updateWorkshops();
                setContextMenu(null);
            })();
        }
    }


    return (
        <>
            <li>
                <Link to={`/admin/overview/${workshop.id}`} className="SmallButton">
                    <Icon path={mdiFolderMultipleOutline}/>
                    <span>{workshop.name}</span>
                </Link>
                <a href="{window.location.href}" onClick={(evt) => {
                    evt.preventDefault();
                    setContextMenu(contextMenu === key ? null : key);
                }} className="ContextMenuButton ContextMenuButton-Center">
                    <Icon path={mdiDotsVertical}/>
                </a>
                <div className={`Menu ContextMenu ContextMenu-Center ${contextMenu !== key ? "Hidden" : ""}`}>
                    <ul>
                        <li>
                            <Link to={`/workshops/${workshop.id}`}>View workshop</Link>
                        </li>
                        <li>
                            <a href={window.location.href} onClick={renameWorkshop}>Rename</a>
                        </li>             
                        <li>
                            <a href={window.location.href} onClick={showStateModal}>Move</a>
                        </li>
                        {/*<li>*/}
                        {/*    <a href={window.location.href}>Delete</a>*/}
                        {/*</li>*/}
                    </ul>
                </div>

                <Modal visible={moveModalVisible} requestClose={() => setMoveModalVisible(false)}>
                    <h1>Select a new status</h1>
                    <form onSubmit={selectState}>
                        <div className="SelectWrapper">
                            <select ref={selectStateRef} defaultValue={workshop.state}>
                                <option value="onboarding">Onboarding</option>
                                <option value="inprogress">In Progress</option>
                                <option value="archive">Archive</option>

                            </select>
                            <Icon path={mdiMenuDown}/>
                        </div>
                    </form>

                    <a href={window.location.href} onClick={selectState} className="PopupButtonConfirm">Confirm</a>
                </Modal>
            </li>
        </>
    );
}

export default function AdminWorkshopOverview() {
    const [workshops, setWorkshops] = useState([]);
    const auth = useAuth();
    const [setError] = useStore(state => [state.setError])

    let sidebar = [
        {
            text: "Workshops",
            icon: mdiAccountMultiple,
            link: "/admin/overview",
        }, {
            text: "Library",
            icon: mdiLibrary,
            link: "/admin/library",
        },
    ];

    let breadcrumbs = [{name: "Client Overview", url: `/admin/overview`}];

    const updateWorkshops = useCallback(() => {
        (async function () {
            setWorkshops(await post('/workshops/list'));
        })();
    }, [auth.data.token, setError]);

    useEffect(() => {
        updateWorkshops();
    }, [updateWorkshops])


    function AddWorkshopButton(evt) {
        evt.preventDefault();

        const name = prompt("Please enter workshop name");
        if (name) {
            (async function () {
                await post('/workshops/add', {name: name});
                updateWorkshops();
            })();
        }
    }

    return (
        <Container sidebar={sidebar}>
            <div className="AdminWorkshopOverview">
                <BreadCrumbs breadcrumbs={breadcrumbs}/>
                <h1>Client Overview</h1>

                <hr/>

                <div className="Section">
                    <div className="Section-Header">
                        <h2>Clients - Onboarding</h2>
                        <p>This section contains new potential workshops</p>
                    </div>
                    <div className="Section-Content">
                        <ul>
                            {workshops.filter(workshop => workshop.state === "onboarding").map(workshop => (<WorkshopButton workshop={workshop} updateWorkshops={updateWorkshops} key={workshop.id}/>))}
                            <li>
                                <a href={document.location.path} onClick={AddWorkshopButton} className="Add-Button">
                                    <Icon path={mdiPlus}/>
                                    <span>Add workshop</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="Section">
                    <div className="Section-Header">
                        <h2>Clients - In Progress</h2>
                        <p>This section contains new potential workshops</p>
                    </div>
                    <div className="Section-Content">
                        <ul>
                            {workshops.filter(workshop => workshop.state === "inprogress").map(workshop => (<WorkshopButton workshop={workshop} updateWorkshops={updateWorkshops} key={workshop.id}/>))}
                        </ul>
                    </div>
                </div>

                <div className="Section">
                    <div className="Section-Header">
                        <h2>Clients - Archive</h2>
                        <p>This section contains new potential workshops</p>
                    </div>
                    <div className="Section-Content">
                        <ul>
                            {workshops.filter(workshop => workshop.state === "archive").map(workshop => (<WorkshopButton workshop={workshop} updateWorkshops={updateWorkshops} key={workshop.id}/>))}
                        </ul>
                    </div>
                </div>
            </div>
        </Container>
    );
}