import React from 'react';
import './Modal.css';
import Icon from "@mdi/react";
import {mdiCloseThick} from "@mdi/js";

export default function Modal({requestClose, children, visible}) {
    
    function close(evt) {
        evt.preventDefault();
        requestClose();
    }

    return (
        <div className={`Modal ${!visible ? "Hidden" : ""}`}>
            <div className="Popup">
                <a href={window.location.href} onClick={close} className="ClosePopup"><Icon path={mdiCloseThick}/></a>
                {children}
            </div>
        </div>
    );
}