import React, {useCallback, useEffect, useState} from 'react';
import './AdminLibrary.css';
import Container from "../Container/Container";
import {mdiAccountMultiple, mdiDotsVertical, mdiFolderMultipleOutline, mdiFolderPlus, mdiLibrary} from "@mdi/js";
import {useAuth} from "../../auth";
import {useStore} from "../../store";
import {Link} from "react-router-dom";
import Icon from "@mdi/react";
import {post} from "../../api/api";


function ModuleTemplateButton({module, refresh}) {
    const key = module.id;
    const contextMenu = useStore(state => state.contextMenu);
    const setContextMenu = useStore(state => state.setContextMenu);
    const [deleted, setDeleted] = useState(false);

    function deleteTemplate(evt) {
        evt.preventDefault();
        if (window.confirm("Are you sure you want to delete this template?")) {
            (async function () {
                await post('/modules/delete', {"moduleId": module.id});
                setDeleted(true);
            })();
        }
    }

    function renameTemplate(evt) {
        evt.preventDefault();

        const name = prompt("Please enter template name");
        if (name) {
            (async function () {
                await post('/modules/rename', {id: module.id, name: name});
                setContextMenu(null);
                refresh();
            })();
        }
    }

    if (deleted) {
        return <></>;
    }

    return (
        <>
            <li className="ModuleTemplate">
                <Link to={`/admin/library/${module.id}`} className="SmallButton">
                    <Icon path={mdiFolderMultipleOutline}/>
                    <span>{module.name}</span>
                </Link>
                <a href="{window.location.href}" onClick={(evt) => {
                    evt.preventDefault();
                    setContextMenu(contextMenu === key ? null : key)
                }} className="ContextMenuButton ContextMenuButton-Center">
                    <Icon path={mdiDotsVertical}/>
                </a>
                <div className={`Menu ContextMenu ContextMenu-Center ${contextMenu !== key ? "Hidden" : ""}`}>
                    <ul>
                        <li>
                            <a href={window.location.href} onClick={renameTemplate}>Rename</a>
                        </li>
                        <li>
                            <a href={window.location.href} onClick={deleteTemplate}>Delete</a>
                        </li>
                    </ul>
                </div>
            </li>
        </>
    );
}

export default function AdminLibrary() {
    const [setError] = useStore(state => [state.setError]);
    const auth = useAuth();
    const [modules, setModules] = useState([]);

    let sidebar = [
        {
            text: "Workshops",
            icon: mdiAccountMultiple,
            link: "/admin/overview",
        }, {
            text: "Library",
            icon: mdiLibrary,
            link: "/admin/library",
        },
    ];

    const init = useCallback(() => {
        (async function () {
            const newVar = await post('/admin/list-module-templates') ?? [];
            setModules(newVar);
        })();
    }, [auth.data.token, setError]);

    useEffect(() => {
        init();
    }, [init])

    function createModuleTemplateButton(evt) {
        evt.preventDefault();

        let moduleTemplateName = prompt("Enter module name")
        if (moduleTemplateName) {
            (async function () {
                await post('/admin/create-module-template', {"moduleName": moduleTemplateName});
                init();
            })();
        }
    }


    return (
        <Container sidebar={sidebar}>
            <div className="AdminLibrary">
                <div className="Desktop-Only">
                    <Link to="#" onClick={createModuleTemplateButton} className="Top-Button"><Icon path={mdiFolderPlus}/></Link>
                </div>

                <h1>Library</h1>
                <p className="PageDescription">This library contains the templates for each Module in their respective folders, and custom alternatives.</p>

                <hr/>
                <div>
                    <ul className="ModuleTemplates">
                        {modules.map(module => (
                            <ModuleTemplateButton module={module} key={module.id} refresh={() => init()}/>
                        ))}
                    </ul>
                </div>
            </div>
        </Container>
    );
}