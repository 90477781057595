import React, {useEffect, useRef, useState} from 'react';
import './ModuleBrowser.css';
import Container from "../Container/Container";
import {mdiAccountMultiple, mdiFolderPlus, mdiLibrary, mdiUpload, mdiViewCarouselOutline} from "@mdi/js";
import {useAuth} from "../../auth";
import {Link, useHistory, useParams} from "react-router-dom";
import Icon from "@mdi/react";
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";
import QRCode from "react-qr-code";
import FileElement from "./FileElement";
import {useStore} from '../../store';
import ImageViewer from "./ImageViewer";
import {post} from "../../api/api";

export default function ModuleBrowser({root}) {
    const {workshopId, moduleId, id} = useParams();
    const fileInputRef = useRef(null);
    const [breadcrumbs, setBreadCrumbs] = useState([]);
    const [sidebar, setSidebar] = useState([]);
    const [showMobileLanding, setShowMobileLanding] = useState(false);
    const auth = useAuth();
    const [fetchModule, fetchDirectoryListing, setError, module, directoryListing] = useStore(state => [state.fetchModule, state.fetchDirectoryListing, state.setError, state.module, state.directoryListing]);
    const history = useHistory();

    let {path} = useParams();
    path = path ? path : "";
    path = path.replaceAll("\\", "/")

    const isTemplate = (module) => !module.workshop;
    const baseUrl = (module) => isTemplate(module) ? `${root}/${moduleId}` : `${root}/${workshopId}/modules/${moduleId}`

    const inAdmin = root.startsWith("/admin");

    const canCreateFolder = auth.data.role === "admin" || auth.data.role === "leader";
    const canUpload = !auth.data.demoUser;

    let mobileMenu = [
    ];
    
    if(canUpload) {
        mobileMenu.push(<React.Fragment key="selectFile"> <a href={window.location.href} onClick={selectFile} key="selectFile"><Icon path={mdiUpload}/></a></React.Fragment>);
    }

    if (canCreateFolder) {
        mobileMenu.push(<React.Fragment key="createFolder"> <a href={window.location.href} onClick={createFolder} key="createFolder"><Icon path={mdiFolderPlus}/></a></React.Fragment>);
    }
        
    function buildSidebar() {
        if(inAdmin) {
            setSidebar([
                {
                    text: "Clients",
                    icon: mdiAccountMultiple,
                    link: "/admin/overview",
                }, {
                    text: "Library",
                    icon: mdiLibrary,
                    link: "/admin/library",
                },
            ]);
        } else {
            setSidebar([
                {
                    text: "Overview",
                    icon: mdiViewCarouselOutline,
                    link: "/workshops/" + workshopId
                }
            ]);
        }
    }

    function buildCrumbPath(module) {
        let crumbs = [];

        if (isTemplate(module)) {
            crumbs.push({name: "Library", url: `${root}`});
            crumbs.push({name: module.name, url: `${root}/${moduleId}`});
        } else {
            crumbs.push({name: module.workshop.name, url: `${root}/${workshopId}`});
            crumbs.push({name: module.name, url: `${root}/${workshopId}/modules/${moduleId}`});
        }

        if (path.length > 0) {
            let base = ""
            let parts = path.split("/");
            for (let i = 0; i < parts.length; i++) {
                let part = parts[i];
                base += `${part}/`;
                crumbs.push({
                    name: part, url: `${baseUrl(module)}/${base}`
                });
            }
        }

        setBreadCrumbs(crumbs);
    }


    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get("m")) {
            setShowMobileLanding(true)
        }


        (async function () {
            let module = await fetchModule(moduleId);
            await fetchDirectoryListing(moduleId, path);
            buildCrumbPath(module);
            buildSidebar(module);
        })();
    }, [workshopId, moduleId, path]);

    function selectFile(evt) {
        evt.preventDefault();
        fileInputRef.current.value = "" // forces trigger change if the same file is uploaded twice
        fileInputRef.current.click();
    }

    function createFolder(evt) {
        evt.preventDefault();
        let folderName = prompt("Enter folder name")
        if (folderName) {
            (async function() {
                await post('/files/create-directory', {
                    moduleId: moduleId,
                    path: `${path}/${folderName}`,
                });

                fetchDirectoryListing(moduleId, path);
            })();
        }
    }

    function hideMobileLanding() {
        const queryParams = new URLSearchParams(window.location.search);
        if (queryParams.has("m")) {
            queryParams.delete("m");
            history.replace({
                search: queryParams.toString()
            })
        }
        setShowMobileLanding(false);
    }

    function upload(evt) {
        evt.preventDefault();

        for (let i = 0; i < evt.target.files.length; i++) {
            let file = evt.target.files[i];

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                (async function () {
                    await post('/files/add', {
                        name: file.name,
                        path: path,
                        moduleId: moduleId,
                        data: reader.result.split(',')[1],
                    });

                    hideMobileLanding();
                    fetchDirectoryListing(moduleId, path);
                })();
            }

            reader.onerror = function (error) {
                setError(`Error: ${error}`);
            };
        }
    }


    return (
        <>
            <ImageViewer/>
            <Container sidebar={sidebar} mobileMenu={mobileMenu}>
                <form>
                    <input type="file" ref={fileInputRef} onChange={upload} accept="*;capture=camera" style={{display: "none"}}/>
                </form>
                {showMobileLanding ?
                    <div className="">
                        <Link to="#" onClick={selectFile} className="bg-workshop-accent-dark h-32 w-full text-workshop-light py-6 rounded-xl shadow-md
                            inline-flex items-center mb-10
                            mt-10 cursor-pointer hover:bg-workshop-accent-light duration-200 px-5">
                            <p className="text-white flex-grow text-left text-2xl">Select file to upload</p>
                            <Icon className="h-20" path={mdiUpload}/>
                        </Link>
                        <p className="text-xl text-center underline text-workshop-accent-darker3 mb-10 no-underline">
                            Or <a href={document.location.href} onClick={(evt) => {
                            evt.preventDefault();
                            hideMobileLanding()
                        }}>click here to see already uploaded files</a>
                        </p>

                        {
                            (auth.data.role === 'admin' || auth.data.role === 'leader') ?
                                <a className="no-underline"
                                        href={`https://input.personunknown.com/qr/v1/${path}`} target="_blank">
                                    <p className="bg-workshop-accent-dark w-full text-xl text-center text-white rounded-xl shadow-md mt-10
                                        cursor-pointer hover:bg-workshop-accent-light duration-200 mb-16 py-6" >
                                            Input structured data for this card
                                    </p>
                                </a>
                                : null
                        }
                    </div>

                    :

                    <div className="ModuleBrowser">
                        <BreadCrumbs breadcrumbs={breadcrumbs}/>
                        <div className="Desktop-Only">
                            {canUpload ?
                                <Link to="#" onClick={selectFile} className="Top-Button"><Icon path={mdiUpload}/></Link>
                                : ""}
                            {canCreateFolder ?
                                <Link to="#" onClick={createFolder} className="Top-Button"><Icon path={mdiFolderPlus}/></Link>
                                : ""}
                        </div>
                        <h1>{module?.name}</h1>
                        <hr/>

                        <div>
                            <ul className="Files">
                                {!inAdmin && canUpload ?
                                    <li className="File QRCode">
                                        <div className="Thumbnail">
                                            <QRCode value={document.location.href + "?m=1"} size={128}/>
                                        </div>
                                        <div className="Filename">
                                            <div className="QRCodeTitle">QR Code</div>
                                            <div className="QRCodeDescription">Scan the QR code to upload your work to this folder!</div>
                                        </div>
                                    </li>
                                :  ""}

                                {directoryListing.map(fileInfo => (
                                    <FileElement fileInfo={fileInfo} baseUrl={baseUrl} module={module} key={fileInfo.path}/>
                                ))}
                            </ul>
                        </div>
                    </div>
                }
            </Container>
        </>
    );
}