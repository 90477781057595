import React, {useState} from 'react';
import LoginHeader from "../Login/LoginHeader";

export default function ForgotPassword() {
    const [username, setUserName] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState();
    const [done, setDone] = useState();

    function onSubmit(evt) {
        evt.preventDefault();
        setError("");

        if (!username) {
            setError("Please provide a valid e-mail address")
            return;
        }


        setLoading(true);
        return fetch(process.env.REACT_APP_API_URL + '/user/reset-password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: username,
            })
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    setError(`Error: ${data.error}`);
                    return;
                }

                setDone(true);
            }).catch((error) => {
                setError("Error: " + error);
            }).finally(() => {
                setLoading(false);
            });
    }


    if (!done) {
        return (
            <div className="Login">
                <LoginHeader/>
                <form onSubmit={onSubmit}>
                    <h1>Forgot Password</h1>
                    <input type="text" name="username" placeholder="Enter an email" onChange={e => setUserName(e.target.value)}/>
                    <p className="error">{error}</p>

                    <div>
                        <button type="submit" className={loading ? "Loading" : ""} disabled={loading}>Send password reset link</button>
                    </div>
                </form>
            </div>
        )

    } else {
        return (
            <div className="Login">
                <LoginHeader/>
                <div className="Message">
                    <h1> Reset e-mail sent</h1>
                    <p className="Message">An e-mail has been sent with a link to change your password. If you don't see the email, check your spam folder.</p>
                </div>
            </div>
        )
    }
}