import React from 'react';
import "./LoadingOverlay.css";
import Lottie from "react-lottie";
import loadingAnimation from "../../animations/loading-blue.json";

export default function LoadingOverlay(props) {
    const lottieOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAnimation,
    };

    return (
            <div className="Loading-Overlay">
                <Lottie options={lottieOptions} width={300}/>
            </div>
    );
}