import './ImageViewer.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import {useStore} from "../../store";


export default function ImageViewer() {
    const [galleryFiles, galleryIndex, setGalleryItem, navigateGallery] = useStore(state => [state.galleryFiles, state.galleryIndex, state.setGalleryItem, state.navigateGallery]);

    if (galleryIndex < 0) {
        return <></>
    }

    return (
        <div className="ImageViewer">
            <Lightbox
                mainSrc={process.env.REACT_APP_API_URL + galleryFiles[galleryIndex].relativeUrl}
                nextSrc={process.env.REACT_APP_API_URL + galleryFiles[(galleryIndex + 1) % galleryFiles.length].relativeUrl}
                prevSrc={process.env.REACT_APP_API_URL + galleryFiles[(galleryIndex + galleryFiles.length - 1) % galleryFiles.length].relativeUrl}
                onCloseRequest={() => setGalleryItem(null)}
                onMovePrevRequest={() => {
                    navigateGallery(-1);
                }}
                onMoveNextRequest={() => {
                    navigateGallery(1);
                }}
            />
        </div>
    );
}